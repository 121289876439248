<template>
    <div class="csv-upload">
        <h2 class="mt-1 font-weight-normal">Upload CSV</h2>
        <filedropper :hidden="inProgress"
            :url="uploadUrl"
            mimeTypes="text/csv"
            @uploadUpdate="uploadUpdate"
            data-test="csvFileInput"
        >
            CSV file
        </filedropper>
        <div v-if="percentCompleted !== null">
            <p class="align-center">
                <span v-if="percentCompleted === 100" data-test="">Upload success.</span>
                <span v-else-if="percentCompleted === 0">Uploading</span>
                <span v-else>{{percentCompleted}}%</span>
            </p>
        </div>
        <div data-test="loading" class="loading-overlay" v-if="inProgress">
            <mercur-spinner></mercur-spinner>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import Filedropper from '@/components/utils/Filedropper'

export default {
    name: 'CsvUpload',
    components: { Filedropper },
    props: {
        closeAction: {

        },
        saveAction: {

        },
        productHash: {

        },
        productConfigurationId: {

        },
    },

    data () {
        return {
            percentCompleted: null,
            loading: false,
            uploadUrl: CONFIG.API.ROUTES.PRODUCTS.CSV.UPLOAD.replace('{supplierId}', this.supplierId).replace('{productHash}', this.productHash).replace('{productConfigurationId}', this.productConfigurationId),
        }
    },
    computed: {
        inProgress () {
            return this.loading || this.percentCompleted !== null
        },
    },
    methods: {
        uploadUpdate ($event) {
            if ($event.type === 'completed') {
                this.loading = true
                const url = CONFIG.API.ROUTES.PRODUCTS.CSV.UPDATE
                    .replace('{supplierId}', this.supplierId)
                    .replace('{productHash}', this.productHash)
                    .replace('{productConfigurationId}', this.productConfigurationId)

                this.addJob(url)
                return this.$api.post(url, {
                    productFile: $event.file.objectName,
                }).then(() => {
                    this.$root.$emit('notification:global', {
                        message: 'CSV file was uploaded successfully and processing started. Please see the product overview to see the progress.',
                        actions: [
                            {
                                text: 'View',
                                action: () => {
                                    this.$router.push({
                                        name: 'SupplierProducts',
                                        params: {
                                            supplierId: this.supplierId,
                                        },
                                    })
                                },
                            },
                        ],
                    })
                    this.closeAction()
                }).catch(({ data }) => {
                    this.$root.$emit('notification:global', {
                        message: `Something went wrong`,
                        type: 'error',
                        errors: data,
                    })
                }).finally(() => {
                    this.finishJob(url)
                    this.loading = false
                })
            }
            this.percentCompleted = $event.percentCompleted
        },
    },
}
</script>

<style lang="scss" scoped>
    .csv-upload {
        max-width: 500px;
    }

    .result {
        text-align: center;
        max-width: 340px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        &--is-error {
            font-size: 13px;
            line-height: 1.3em;
            color: red;
        }
    }
</style>
