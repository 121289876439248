<template>
    <div class="product-view full-height-layout" v-if="resource">
        <div class="row">
            <div class="col col-8">
                <mercur-card>
                    <h2>{{resource.productConfigurationName}}</h2>
                    <p>
                        Created <strong>{{resource.dateCreated | asDatetime}}</strong><br />
                        Last update <strong>{{resource.dateUpdated | asDatetime}}</strong>
                    </p>
                    <ul>
                        <li v-if="resource.processedData && resource.processedData.variations">
                            This product has {{resource.processedData.variations}} variations.
                        </li>
                        <li>
                            It has <strong>{{totalAttributes}}</strong> attributes with a total of <strong>{{totalAttributeOptions}}</strong> attribute options
                        </li>
                        <li>
                            There are <strong>{{resource.attributeConfiguration.blacklistedAttributes.length}}</strong> blacklisted combinations.
                        </li>
                    </ul>

                </mercur-card>
            </div>
            <div class="col col-4">
                <mercur-card>
                    <div class="d-flex justify-content-between align-items-end">
                        <mercur-select class="flex-1 mb-0 mr-2" v-model="productStatus" :disabled="loading">
                            <template slot="label">Status</template>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                        </mercur-select>
                        <mercur-button :disabled="loading" @click="onStatusChange" class="btn btn-yellow btn-icon"><i class="fas fa-save"></i></mercur-button>
                    </div>

                    <hr />
                    <h4>Maintenance</h4>
                    <mercur-item :to="editProductRoute"><i class="fas fa-edit"></i>Edit product</mercur-item>
                    <mercur-item @click.native="removeProductPopupActive = true"><i class="fas fa-trash"></i>Remove</mercur-item>
                    <hr />
                    <h4>CSV</h4>
                    <mercur-item @click.native='downloadCsv' :disabled="downloadPending"><i class="fas fa-download"></i>Download CSV</mercur-item>
                    <mercur-item v-if="isAllowedTo('SupplierCentral/updateProductConfigurationCosts')" @click.native='uploadCsvPopupActive = true'><i class="fas fa-upload"></i>Upload CSV</mercur-item>
                    <hr />
                    <h4>Templates</h4>
                    <mercur-item
                        v-for="section in templateSections"
                        :key="section.value"
                        :to="viewTemplatesRoute(section)"
                    >
                        <i :class="section.icon"></i>
                        {{section.title}}</mercur-item>
                    <hr />
                    <h4>Other</h4>
                    <mercur-item v-if="viewSkusRoute" :to="viewSkusRoute"><i class="fas fa-list"></i>View sku's</mercur-item>
                    <mercur-item v-if="viewPackagesRoute" :to="viewPackagesRoute"><i class="fas fa-box-open"></i>Packages</mercur-item>
                </mercur-card>
            </div>
        </div>
        <mercur-dialog :is-open.sync="uploadCsvPopupActive">
            <csv-upload :product-hash="productHash" :product-configuration-id="productConfigurationId" :closeAction="() => {uploadCsvPopupActive = false}"></csv-upload>
        </mercur-dialog>
        <template v-if="resource">
            <mercur-dialog
                :is-open.sync="removeProductPopupActive"
            >
                <div slot="header">
                    <h2>Are you sure you want to remove this product?</h2>
                </div>
                <pre>{{resource.productConfigurationName}}</pre>
                <div slot="footer">
                    <mercur-button class="btn" @click="removeProductPopupActive = false">Cancel</mercur-button>
                    <mercur-button class="btn btn-yellow" @click="removeProductConfiguration">Remove</mercur-button>
                </div>
            </mercur-dialog>
        </template>
    </div>
</template>

<script>

import CONFIG from '@root/config'
import CsvUpload from '@/components/CsvUpload'

export default {
    name: 'ProductDetail',
    components: { CsvUpload },

    data () {
        return {
            selectedAttributesWithOptions: [],
            selectedBlacklists: null,
            success: null,
            loading: false,
            uploadCsvPopupActive: false,
            downloadPending: false,
            productStatus: null,
            templateSections: CONFIG.PRODUCT_TEMPLATE_SECTIONS.filter(section => this.isAllowedTo(section.requiredPermissions)),
            removeProductPopupActive: false,
        }
    },

    computed: {
        productNames () {
            if (!this.resource || !this.resource.productNames) {
                return ''
            }

            return [...new Set(this.resource.productNames)].join(', ')
        },
        productStatusSwitch: {
            get () {
                return this.productStatus === 'ACTIVE'
            },
            set (value) {
                this.productStatus = value ? 'ACTIVE' : 'INACTIVE'
            },
        },
        productHash () {
            if (this.resource) {
                return this.resource.productHash
            }
            return ''
        },
        productConfigurationId () {
            return this.$route.params.productConfigurationId
        },

        resource () {
            const supplierId = this.supplierId
            const productConfigurationId = this.$route.params.productConfigurationId

            const resource = this.$store.state.products.products[`${supplierId}_${productConfigurationId}`]

            if (resource) {
                return resource
            }

            return null
        },

        editProductRoute () {
            return {
                name: 'SupplierProductsEdit',
                params: {
                    supplierId: this.supplierId,
                    productConfigurationName: this.resource.productConfigurationName,
                    previouslySelectedAttributes: this.resource.attributeConfiguration.selectedAttributes,
                    previouslySelectedBlacklists: this.resource.attributeConfiguration.blacklistedAttributes,
                },
            }
        },

        viewSkusRoute () {
            if (!this.isAllowedTo('SupplierCentral/getProductVariationsBySupplierIdAndProductHash')) {
                return null
            }
            return {
                name: 'SupplierProductVariationsView',
                params: {
                    productConfigurationId: this.productConfigurationId,
                    supplierId: this.supplierId,
                },
            }
        },

        viewPackagesRoute () {
            if (!this.isAllowedTo('SupplierCentral/getPackageConfigurationsBySupplierId')) {
                return null
            }
            return {
                name: 'SupplierProductPackagesView',
                params: {
                    productConfigurationId: this.productConfigurationId,
                    supplierId: this.supplierId,
                },
            }
        },

        totalAttributes () {
            return Object.values(this.resource.attributeConfiguration.selectedAttributes).length
        },

        totalAttributeOptions () {
            return Object.values(this.resource.attributeConfiguration.selectedAttributes).reduce((accumulator, attributeOptions) => {
                return accumulator + attributeOptions.length
            }, 0)
        },
    },

    methods: {
        removeProductConfiguration () {
            const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.DELETE
                .replace('{supplierId}', this.supplierId)
                .replace('{productConfigurationId}', this.productConfigurationId)

            this.loading = true
            this.addJob(url)
            this.$api.post(url, {}).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Product deletion has been scheduled.`,
                })

                setTimeout(() => {
                    this.$router.push({
                        name: 'SupplierProducts',
                        params: {
                            supplierId: this.supplierId,
                        },
                    })
                }, 1500)
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Deleting product failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
        viewTemplatesRoute (section) {
            return {
                name: 'SupplierProductTemplatesView',
                params: {
                    productConfigurationId: this.productConfigurationId,
                    supplierId: this.supplierId,
                    templateSection: section.value,
                },
            }
        },
        onStatusChange (value) {
            const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.SET_STATUS
            this.loading = true
            this.addJob(url)
            this.$api.post(url, {
                supplierId: this.supplierId,
                productConfigurationId: this.productConfigurationId,
                status: this.productStatus,
            }).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Product status was changed.`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Setting product status failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
                this.productStatusSwitch = !value
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
        downloadCsv () {
            const url = CONFIG.API.ROUTES.PRODUCTS.CSV.DOWNLOAD
                .replace('{supplierId}', this.supplierId)
                .replace('{productHash}', this.productHash)
                .replace('{productConfigurationId}', this.productConfigurationId)
            const payload = {
                fileName: `${this.resource.productConfigurationName}`,
            }
            this.downloadPending = true
            this.addJob(url)
            this.$store.dispatch('tasks/addTask', {
                title: `Download product detail csv`,
                interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                retryOnFail: false,
                onComplete: data => {
                    this.$bus.$emit('DOWNLOAD_URL_RESOLVED', data.url)
                },
                onPoll: () => {
                    this.$root.$emit('notification:global', {
                        message: `Download started.`,
                    })
                    return this.$api.post(url, payload).catch(data => {
                        this.$root.$emit('notification:global', {
                            message: `Downloading failed. Please try again.`,
                            type: 'error',
                            errors: data,
                        })
                    }).finally(() => {
                        this.downloadPending = false
                        this.finishJob(url)
                    })
                },
                handleResult: (result) => {
                    if (result.data.length === 0) {
                        return Promise.resolve(null)
                    }
                    return Promise.resolve(result.data)
                },
            })
        },
    },

    watch: {
        'resource' (value) {
            if (!value) {
                return
            }

            this.productStatus = value.productStatus || 'ACTIVE'
            if (this.breadcrumbElement) {
                this.breadcrumbElement.name = value.productConfigurationName
            }
        },
    },
}
</script>
